import {
  CheckBoxOutlined,
  CheckBoxTwoTone,
  ThumbDownOutlined,
  ThumbDownTwoTone,
  ThumbUpOutlined,
  ThumbUpTwoTone,
} from '@mui/icons-material';
import {AiInfo} from '../AiInfo/AiInfo';
import {CSSProperties} from 'react';
import {DeepReadOnly} from '../misc';
import {pl_types} from '../../generated/protobuf-js';
import {EditablePlainText} from '../EditablePlainText';
import IProject = pl_types.IProject;
import ThumbsState = pl_types.Project.ThumbsState;

export function ProjectCardHeader(
  props: DeepReadOnly<{
    project: IProject;
    hideActive?: boolean;
    hideThumbsState?: boolean;
    updateProject?: (project: DeepReadOnly<IProject>) => void;
    style?: CSSProperties;
    isTitleNotEditable?: boolean;
  }>
) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        textWrap: 'nowrap',
        gap: '0.5rem',
        ...props.style,
      }}
    >
      {!props.hideActive &&
        (props.project.active ? (
          <CheckBoxTwoTone
            htmlColor="#00cc00"
            onClick={() =>
              props.updateProject?.({...props.project, active: false})
            }
            cursor="pointer"
          />
        ) : (
          <CheckBoxOutlined
            htmlColor="#cccccc"
            onClick={() =>
              props.updateProject?.({...props.project, active: true})
            }
            cursor="pointer"
          />
        ))}
      <div
        style={{
          fontWeight: 'bold',
          fontSize: '1.1rem',
          textWrap: 'balance',
          flex: '1 1 auto',
        }}
      >
        {props.isTitleNotEditable ? (
          <div>{props.project.name}</div>
        ) : (
          <EditablePlainText
            onChange={(text: string) =>
              props.updateProject?.({...props.project, name: text})
            }
            value={props.project.name}
            viewingPlaceholder="Click to Add Project Title"
          />
        )}
      </div>
      {!props.hideThumbsState && (
        <>
          {props.project.thumbsState === ThumbsState.THUMBS_UP ? (
            <ThumbUpTwoTone
              htmlColor="#0000cc"
              onClick={() =>
                props.updateProject?.({
                  ...props.project,
                  thumbsState: ThumbsState.UNSET_THUMBS_STATE,
                })
              }
              cursor="pointer"
            />
          ) : (
            <ThumbUpOutlined
              htmlColor="#cccccc"
              onClick={() =>
                props.updateProject?.({
                  ...props.project,
                  thumbsState: ThumbsState.THUMBS_UP,
                })
              }
              cursor="pointer"
            />
          )}
          {props.project.thumbsState === ThumbsState.THUMBS_DOWN ? (
            <ThumbDownTwoTone
              htmlColor="#cc0000"
              onClick={() =>
                props.updateProject?.({
                  ...props.project,
                  thumbsState: ThumbsState.UNSET_THUMBS_STATE,
                })
              }
              cursor="pointer"
            />
          ) : (
            <ThumbDownOutlined
              htmlColor="#cccccc"
              onClick={() =>
                props.updateProject?.({
                  ...props.project,
                  thumbsState: ThumbsState.THUMBS_DOWN,
                })
              }
              cursor="pointer"
            />
          )}
        </>
      )}
      <AiInfo aiInfo={props.project.projectDefinition} />
    </div>
  );
}
