import React, {PropsWithChildren, useEffect} from 'react';
import {createBrowserRouter, useLocation} from 'react-router-dom';
import {Root} from './pages/Root';
import {Login} from './pages/user_xs/Login/Login';
import {EditDistricts} from './pages/profiles/EditDistricts/EditDistricts';
import {EditSchools} from './pages/profiles/EditSchools/EditSchools';
import {DefaultPageNav} from './libs/DefaultPage/DefaultPageNav';
import {MyAccount} from './pages/user_xs/MyAccount/MyAccount';
import {PrivacyPolicy} from './pages/docs/PrivacyPolicy';
import {Accounts} from './pages/admin_x/Accounts/Accounts';
import {StudentDashboard} from './pages/dashboards/StudentDashboard/StudentDashboard';
import {TeacherDashboard} from './pages/dashboards/TeacherDashboard/TeacherDashboard';
import {AdminXDashboard} from './pages/dashboards/AdminXDashboard/AdminXDashboard';
import {RedirectToDashboard} from './pages/dashboards/RedirectToDashboard';
import {DemoProjectBuilder} from './pages/demos/DemoProjectBuilder/DemoProjectBuilder';
import {MechanicalEngineering} from './pages/schools/dvs/MechanicalEngineering';
import {SchoolsIndex} from './pages/schools/SchoolsIndex';
import {ClassXManagement} from './pages/class_x_management/ClassXManagement';
import {ProjectsDashboard} from './pages/projects/ProjectsDashboard';
import {About} from './pages/about/About';
import {ProjectLeo} from './pages/project_leo/ProjectLeo';
import {AuthorizedRoute} from './components/AuthorizedRoute';
import {Unauthorized} from './pages/unauthorized/Unauthorized';

function GaTags(props: PropsWithChildren<{title: string}>) {
  const location = useLocation();

  useEffect(() => {
    if ('gtag' in window && typeof window.gtag === 'function') {
      document.title = 'Project Leo: ' + props.title;
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return <>{props.children}</>;
}

export const router = createBrowserRouter([
  {
    path: '/users/login.html',
    element: (
      <GaTags title="Login">
        <Login />
      </GaTags>
    ),
  },
  {
    path: '/admin',
    element: <DefaultPageNav />,
    children: [
      {
        path: 'accounts.html',
        element: (
          <AuthorizedRoute isUserXAuthorized={userX => userX.isAdminX}>
            <GaTags title="Account Administration">
              <Accounts />
            </GaTags>
          </AuthorizedRoute>
        ),
      },
    ],
  },
  {
    path: '/class-management',
    element: <DefaultPageNav />,
    children: [
      {
        path: 'class-management.html',
        element: (
          <AuthorizedRoute
            isUserXAuthorized={userX => userX.isAdminX || userX.isTeacher}
          >
            <GaTags title="Class Management">
              <ClassXManagement />
            </GaTags>
          </AuthorizedRoute>
        ),
      },
    ],
  },
  {
    path: '/demos',
    children: [
      {
        path: 'project-builder.html',
        element: (
          <GaTags title="Demo Project Builder">
            <DemoProjectBuilder />
          </GaTags>
        ),
      },
    ],
  },
  {
    path: '/dashboards/redirect.html',
    element: <RedirectToDashboard />,
  },
  {
    path: '/dashboards',
    element: <DefaultPageNav />,
    children: [
      {
        path: 'admin-dashboard.html',
        element: (
          <AuthorizedRoute isUserXAuthorized={userX => userX.isAdminX}>
            <GaTags title="Admin Dashboard">
              <AdminXDashboard />
            </GaTags>
          </AuthorizedRoute>
        ),
      },
      {
        path: 'student-dashboard.html',
        element: (
          <AuthorizedRoute
            isUserXAuthorized={userX => userX.isAdminX || userX.isStudent}
          >
            <GaTags title="Student Dashboard">
              <StudentDashboard />
            </GaTags>
          </AuthorizedRoute>
        ),
      },
      {
        path: 'teacher-dashboard.html',
        element: (
          <AuthorizedRoute
            isUserXAuthorized={userX => userX.isAdminX || userX.isTeacher}
          >
            <GaTags title="Teacher Dashboard">
              <TeacherDashboard />
            </GaTags>
          </AuthorizedRoute>
        ),
      },
    ],
  },
  {
    path: '/users',
    element: <DefaultPageNav />,
    children: [
      {
        path: 'my-account.html',
        element: (
          <AuthorizedRoute
            isUserXAuthorized={userX =>
              userX.isAdminX ||
              userX.isTeacher ||
              userX.isStudent ||
              userX.isDemo
            }
          >
            <GaTags title="My Account">
              <MyAccount />
            </GaTags>
          </AuthorizedRoute>
        ),
      },
    ],
  },
  {
    path: '/docs',
    element: <DefaultPageNav />,
    children: [
      {
        path: 'privacy-policy.html',
        element: (
          <GaTags title="Privacy Policy">
            <PrivacyPolicy />
          </GaTags>
        ),
      },
    ],
  },
  {
    path: '/profiles',
    element: <DefaultPageNav />,
    children: [
      {
        path: 'edit-districts.html',
        element: (
          <AuthorizedRoute isUserXAuthorized={userX => userX.isAdminX}>
            <GaTags title="Edit Districts">
              <EditDistricts />
            </GaTags>
          </AuthorizedRoute>
        ),
      },
      {
        path: 'edit-schools.html',
        element: (
          <AuthorizedRoute isUserXAuthorized={userX => userX.isAdminX}>
            <GaTags title="Edit Schools">
              <EditSchools />
            </GaTags>
          </AuthorizedRoute>
        ),
      },
    ],
  },
  {
    path: '/projects',
    element: <DefaultPageNav />,
    children: [
      {
        path: 'projects.html',
        element: (
          <AuthorizedRoute
            isUserXAuthorized={userX =>
              userX.isAdminX ||
              userX.isTeacher ||
              userX.isStudent ||
              userX.isDemo
            }
          >
            <GaTags title="Projects Dashboard">
              <ProjectsDashboard />
            </GaTags>
          </AuthorizedRoute>
        ),
      },
    ],
  },
  {
    path: '/schools',
    children: [
      {
        path: 'index.html',
        element: (
          <GaTags title="Schools">
            <SchoolsIndex />
          </GaTags>
        ),
      },
      {
        path: 'dvs',
        children: [
          {
            path: 'mechanical-engineering.html',
            element: (
              <GaTags title="School of Mechanical Engineering">
                <MechanicalEngineering />
              </GaTags>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: '',
        element: (
          <GaTags title="Engaging Students. Empowering Teachers.">
            <Root />
          </GaTags>
        ),
      },
      {
        path: '/',
        element: (
          <GaTags title="Engaging Students. Empowering Teachers.">
            <Root />
          </GaTags>
        ),
      },
      {
        path: 'about',
        element: (
          <GaTags title="About">
            <About></About>
          </GaTags>
        ),
      },
      {
        path: 'project_leo',
        element: (
          <GaTags title="How Project Leo Works">
            <ProjectLeo></ProjectLeo>
          </GaTags>
        ),
      },
    ],
  },
  {
    path: '/unauthorized',
    element: (
      <GaTags title="Unauthorized">
        <Unauthorized />
      </GaTags>
    ),
  },
]);
