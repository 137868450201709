import './LoginForm.scss';
import {Backdrop, Button, Grid, Modal, TextField} from '@mui/material';
import {FormEvent, useEffect, useState} from 'react';
import {Comment, Email, Lock, Person} from '@mui/icons-material';
import {useFormFields} from '../form_utils/forms';
import {TabbedPanel} from '../TabbedPanel/TabbedPanel';
import {createService} from '../protos';
import {user_x_service} from '../../generated/protobuf-js';
import {Link} from 'react-router-dom';
import {AppDispatch, RootState} from '../../store/store';
import {useDispatch, useSelector} from 'react-redux';
import {login, resetStatus} from '../../features/auth/authSlice';
import {APIStateStatusEnum} from '../../shared/enums/apiStateStatus.enum';
import UserXService = user_x_service.UserXService;

const AUTHENTICATION_FAILURE =
  'Invalid username or password. Please try again.';

enum LoginTab {
  LOGIN,
  SIGN_UP,
}

export function LoginForm(props: {
  hideLogo?: boolean;
  onSuccess: () => void;
  onFailure: () => void;
  onError: (error?: Error | string) => void;
  onCancel: () => void;
  className?: string;
}) {
  const dispatch: AppDispatch = useDispatch();
  const authStatus = useSelector((state: RootState) => state.auth.status);
  const authError = useSelector((state: RootState) => state.auth.error);
  const [showLogInErrorText, setShowLogInErrorText] = useState<string>();
  const [showSignUpErrorText, setShowSignUpErrorText] = useState<string>();

  const loginForm = useFormFields();
  const username = loginForm.useStringFormField('username', {
    isEmail: true,
    startIcon: <Email />,
    maxLength: 254,
  });
  const password = loginForm.useStringFormField('password', {
    isPassword: {
      skipPasswordCheck: true,
    },
    startIcon: <Lock />,
  });

  const signUpForm = useFormFields();
  const firstName = signUpForm.useStringFormField('firstName', {
    startIcon: <Person />,
    maxLength: 255,
  });
  const lastName = signUpForm.useStringFormField('lastName', {
    startIcon: <Person />,
    maxLength: 255,
  });
  const email = signUpForm.useStringFormField('emailAddress', {
    isEmail: true,
    startIcon: <Email />,
    maxLength: 254,
  });
  const newPassword = signUpForm.useStringFormField('password', {
    isPassword: {},
    startIcon: <Lock />,
  });
  const verifyPassword = signUpForm.useStringFormField('verifyPassword', {
    isPassword: {},
    startIcon: <Lock />,
  });
  const reasonForInterest = signUpForm.useStringFormField('reasonForInterest', {
    startIcon: <Comment />,
    maxLength: 8192,
  });

  useEffect(() => {
    if (authStatus === APIStateStatusEnum.SUCCEEDED) {
      dispatch(resetStatus());
      props.onSuccess();
    } else if (authStatus === APIStateStatusEnum.FAILED) {
      setShowLogInErrorText(AUTHENTICATION_FAILURE);
      props.onError(authError || '');
    }
  }, [authStatus]);

  function hideErrorText() {
    setShowLogInErrorText(undefined);
    setShowSignUpErrorText(undefined);
  }

  async function onLogin(e: FormEvent<HTMLFormElement>) {
    // Prevent the form from reloading the page.
    e.preventDefault();

    if (!loginForm.verifyOk(true)) {
      return;
    }

    const username1 = username.getValue();
    const password1 = password.getValue();
    if (username1 && password1) {
      dispatch(login({username: username1, password: password1}));
    }
  }

  async function onSignUp(e: FormEvent<HTMLFormElement>) {
    // Prevent the form from reloading the page.
    e.preventDefault();

    if (!signUpForm.verifyOk(true)) {
      return;
    }

    createService(UserXService, 'UserXService')
      .registerUserX(signUpForm.getValuesObject())
      .then(response => {
        if (response.error != null) {
          setShowSignUpErrorText(response.error);
          return;
        }

        dispatch(
          login({
            username: email.getValue()!,
            password: newPassword.getValue()!,
          })
        );
        // usernamePasswordLogin(
        //   global,
        //   email.getValue()!,
        //   newPassword.getValue()!,
        //   props.onSuccess,
        //   () => global.setError('Failed to log in newly signed up user.'),
        //   props.onError
        // );
      });
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className={`${props.className ?? ''} login-form`}>
        {!props.hideLogo && (
          <Link to="/">
            <img src="/images/logos/LinkedIn.png" className="login-form-logo" />
          </Link>
        )}
        <div className="login-form-oauth-grid">
          <Grid container spacing="1em" sx={{width: '600px'}}>
            <Grid item xs={6}>
              <a
                href="/oauth2/authorization/google"
                style={{color: 'black', textDecoration: 'none'}}
              >
                <div className="oauth2-button">
                  <img
                    src="/images/oauth2/google.svg"
                    width="100%"
                    height="100%"
                  />
                  <span>Log in with Google</span>
                </div>
              </a>
            </Grid>
            <Grid item xs={6}>
              <a
                href="/oauth2/authorization/clever"
                style={{color: 'black', textDecoration: 'none'}}
              >
                <div className="oauth2-button">
                  <img
                    src="/images/oauth2/clever.png"
                    width="100%"
                    height="100%"
                  />
                  <span>Log in with Clever</span>
                </div>
              </a>
            </Grid>
          </Grid>
        </div>
        <TabbedPanel
          tabKeyEnum={LoginTab}
          defaultTabKey={LoginTab.LOGIN}
          className="login-form-tabs"
          tabs={[
            {
              key: LoginTab.LOGIN,
              label: 'Log In',
              content: (
                <form onSubmit={onLogin} noValidate={true}>
                  <Grid container spacing={3} paddingTop={4}>
                    <Grid item xs={12} className="login-form-field">
                      <TextField
                        required
                        autoComplete="email"
                        label="Email Address"
                        {...username.getTextFieldParams()}
                      />
                    </Grid>
                    <Grid item xs={12} className="login-form-field">
                      <TextField
                        required
                        autoComplete="current-password"
                        label="Password"
                        {...password.getTextFieldParams()}
                      />
                    </Grid>
                    <Grid item xs={12} className="login-form-buttons">
                      <Button variant="contained" type="submit">
                        Log In
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              ),
            },
            {
              key: LoginTab.SIGN_UP,
              label: 'Sign Up',
              content: (
                <form onSubmit={onSignUp} noValidate={true}>
                  <Grid container spacing={3} paddingTop={4}>
                    <Grid item xs={12} md={6} className="login-form-field">
                      <TextField
                        required
                        autoComplete="given-name"
                        label="First Name"
                        {...firstName.getTextFieldParams()}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className="login-form-field">
                      <TextField
                        required
                        autoComplete="family-name"
                        label="Last Name"
                        {...lastName.getTextFieldParams()}
                      />
                    </Grid>
                    <Grid item xs={12} className="login-form-field">
                      <TextField
                        required
                        autoComplete="email"
                        label="Email Address"
                        {...email.getTextFieldParams()}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className="login-form-field">
                      <TextField
                        required
                        autoComplete="new-password"
                        label="Password"
                        {...newPassword.getTextFieldParams()}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className="login-form-field">
                      <TextField
                        required
                        autoComplete="new-password"
                        label="Verify Password"
                        {...verifyPassword.getTextFieldParams()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        rows={3}
                        label="How did you hear about us? (Optional)"
                        {...reasonForInterest.getTextFieldParams()}
                      />
                    </Grid>
                    <Grid item xs={12} className="login-form-buttons">
                      <Button variant="contained" type="submit">
                        Sign Up
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              ),
            },
          ]}
        />
      </div>
      <Modal
        open={showLogInErrorText != null || showSignUpErrorText != null}
        onClose={hideErrorText}
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        onKeyDown={e => {
          if (e.key === 'Escape' || e.key === 'Enter') {
            e.preventDefault();
            hideErrorText();
          }
        }}
      >
        <div className="login-form-already-exists-modal">
          <div>{showLogInErrorText != null ? 'Log In' : 'Sign Up'} Error</div>
          <div>{showLogInErrorText ?? showSignUpErrorText}</div>
          <Button
            variant="contained"
            onClick={hideErrorText}
            style={{whiteSpace: 'nowrap'}}
          >
            Try Again
          </Button>
        </div>
      </Modal>
    </>
  );
}
