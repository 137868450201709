import {Button, Grid} from '@mui/material';
import {LandingPageLayout} from '../../Layouts/LandingPageLayout';
import {styled} from 'styled-components';
import './index.scss';
import {useNavigate} from 'react-router';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {PopupModal} from 'react-calendly';
import {useState} from 'react';

const HeroSubheader = styled.div`
  font-weight: bold;
  ${props => props.theme.breakpoints.up('sm')} {
    font-size: 24px;
  }
`;

export function Home() {
  const navigate = useNavigate();
  const [isCalendlyPopupOpne, setIsCalendlyPopupOpne] = useState(false);
  return (
    <LandingPageLayout>
      <div className="first-section">
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} className="left-section image">
            <img
              className="image-alignment"
              src="/images/landing/projects_daigram1.png"
              style={{maxWidth: '480px'}}
            />
          </Grid>
          <Grid item xs={12} md={6} className="right-section">
            <h1>
              Connecting
              <br />
              Classrooms to Careers
            </h1>
            <HeroSubheader>
              Project Leo is an AI-powered LMS add-on that simplifies a
              teacher’s workload by personalizing career-focused projects for
              students.
            </HeroSubheader>
            <p className="main-body-text text-description">
              {`Developed by educators, Project Leo boosts student 
engagement, reduces burnout, and streamlines class management by personalizing projects that prepare students for their future careers.`}
            </p>
            <div className="buttons-section">
              <Button
                className="btn-free-trial"
                onClick={() => navigate('/users/login.html?tab=SIGN_UP')}
                variant="contained"
                size="large"
              >
                Create Your First Project
              </Button>
              <Button
                onClick={() => navigate('/demos/project-builder.html')}
                variant="contained"
                size="large"
              >
                How does Project Leo Work?
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="sepration-line"></div>
      <div className="second-section">
        <div className="content-center">
          <h1>Struggling to Spark Student Interest? </h1>
          <h1>You’re Not Alone.</h1>
        </div>
        <p>
          As a teacher, creating personalized hooks for your students can feel
          like an uphill battle. Even seasoned educators find it challenging to
          connect learning objectives with each student’s unique personal
          interests and career goals. As teachers ourselves, we found the most
          common hurdles to include:
        </p>
        <Grid container spacing={1} sx={{flexWrap: 'unset', gap: '7vw'}}>
          <Grid item xs={12} md={6}>
            <div className="box">
              <h3>Creative Burnout</h3>
              <div className="sub-heading">
                <p>Constantly inventing engaging</p>
                <p>hooks drains your energy and time.</p>
              </div>
              <div>
                <img src="/images/fire-icon.svg" />
              </div>
            </div>
            <div className="box-bottom content-center">
              <Button
                className="btn-free-trial"
                onClick={() => navigate('/users/login.html')}
                variant="contained"
                size="large"
              >
                Create Your First Project
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="second-box">
            <div className="box">
              <h3>Administrative Overload</h3>
              <div className="sub-heading">
                <p>Tracking personalized work manually</p>
                <p>eats into your teaching time.</p>
              </div>
              <div>
                <img src="/images/ErrorFilled.svg" />
              </div>
            </div>
            <div className="box-bottom content-center">
              <Button
                onClick={() => navigate('/demos/project-builder.html')}
                variant="contained"
                size="large"
              >
                How does Project Leo Work?
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="sepration-line"></div>
      <div className="third-section">
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} className="left-section">
            <h1>
              Project Leo allows
              <br />
              teachers to:
            </h1>
            <div className="points-list">
              <div className="point">
                <TaskAltIcon sx={{width: '40px', height: '40px'}} />
                <p>
                  Help students to generate personalized career-focused projects
                  in minutes
                </p>
              </div>
              <div className="point">
                <TaskAltIcon sx={{width: '40px', height: '40px'}} />
                <p>Simplify the monitoring of student work</p>
              </div>
              <div className="point">
                <TaskAltIcon sx={{width: '40px', height: '40px'}} />
                <p>Provide timely feedback on student reflections</p>
              </div>
              <div className="point">
                <TaskAltIcon sx={{width: '40px', height: '40px'}} />
                <p>Focus on what matter most: providing direct instruction</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={8} className="right-section image">
            <img
              className="image-alignment"
              src="/images/landing/projects/ProjectBuilderImage.png"
            />
          </Grid>
        </Grid>
      </div>
      <div className="fourth-section">
        <h3>The Project Leo in Action</h3>
        <p>
          Teachers, Students, and Industry Leaders Share Their Experiences with
          Project Leo
        </p>
        <div className="quotes-list">
          <Grid container spacing={1} sx={{flexWrap: 'unset', gap: '3vw'}}>
            <Grid item xs={12} md={4}>
              <div className="quote">
                <div className="card-body">
                  The application of AI in learning that I’m most excited about
                  is in career-connected learning, and the best example of that
                  in the world is here …. with Project Leo… I love this
                  application of AI because it engages young people in
                  co-constructing work that matters… I’m not more excited about
                  anything else than Project Leo.
                </div>
                <div className="user-info">
                  {/* <div className="image">
                    <img src="/images/user-icon.png" alt="" />
                  </div> */}
                  <div className="user">
                    <div className="user-title">Tom Vander Ark</div>
                    <div className="user-description">
                      CEO of Getting Smart and former Executive Director for
                      Education at the Bill & Melinda Gates Foundation
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="quote">
                <div className="card-body">
                  My Project Leo experience felt like the start of a new way of
                  learning in the education system.
                </div>
                <div className="user-info">
                  {/* <div className="image">
                    <img src="/images/user-icon.png" alt="" />
                  </div> */}
                  <div className="user">
                    <div className="user-title">Josh</div>
                    <div className="user-description">
                      11th Grade Student, Los Angeles
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="quote">
                <div className="card-body">
                  Honestly, my Project Leo experience was eye opening. By
                  learning these skills I realized how there is nothing stopping
                  me from pursuing something I am passionate about. The projects
                  done in class showed me that I can literally make anything in
                  the world that I want to at any time.
                </div>
                <div className="user-info">
                  {/* <div className="image">
                    <img src="/images/user-icon.png" alt="" />
                  </div> */}
                  <div className="user">
                    <div className="user-title">Henry</div>
                    <div className="user-description">
                      11th Grade Student, Los Angeles
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{flexWrap: 'unset', gap: '3vw'}}>
            <Grid item xs={12} md={4}>
              <div className="quote">
                <div className="card-body">
                  I wasn't really sure what I expected when I introduced Project
                  Leo to my World History students, but I certainly didn't
                  expect the level of enthusiasm and engagement that it ignited.
                  Witnessing their genuine excitement was truly
                  inspiring….Project Leo addresses a critical missing piece in
                  traditional school projects – empowering students with agency
                  and ownership over their learning.
                </div>
                <div className="user-info">
                  {/* <div className="image">
                    <img src="/images/user-icon.png" alt="" />
                  </div> */}
                  <div className="user">
                    <div className="user-title">Julie</div>
                    <div className="user-description">
                      10th Grade History teacher, El Segundo
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="quote">
                <div className="card-body">
                  I've liked the idea of learning through a project I actually
                  find interest in rather than just being told to retain
                  something.
                </div>
                <div className="user-info">
                  {/* <div className="image">
                    <img src="/images/user-icon.png" alt="" />
                  </div> */}
                  <div className="user">
                    <div className="user-title">Andrew</div>
                    <div className="user-description">
                      12th Grade Student, Torrance
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="quote">
                <div className="card-body">
                  Project Leo helps students and teachers meet at a common
                  ground. The teachers learn about the students’ passions and
                  the students understand how they can apply what they’ve been
                  learning in class. Instead of doing projects I think are
                  interesting, Project Leo helps identify what projects the
                  students want to do, all while making sure they’re
                  demonstrating growth in essential skills covered in class.
                </div>
                <div className="user-info">
                  {/* <div className="image">
                    <img src="/images/user-icon.png" alt="" />
                  </div> */}
                  <div className="user">
                    <div className="user-title">Wendy</div>
                    <div className="user-description">
                      10th Grade English Teacher, El Segundo
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="fifth-section">
        <div className="content">
          {/* <div className="image">
            <img src="/images/Phone.png" alt="" />
          </div> */}
          <div>
            <h2>Experience Project Leo in Action</h2>
            <p>
              Book a personalized demo and discover how to transform your
              classroom today.
            </p>
            <div className="action-buttons">
              <PopupModal
                url="https://calendly.com/matt-projectleo/30min"
                rootElement={document.getElementById('root') as HTMLElement}
                // pageSettings={{
                //   backgroundColor: 'ffffff',
                //   hideEventTypeDetails: false,
                //   hideLandingPageDetails: false,
                //   primaryColor: '00a2ff',
                //   textColor: '4d5055',
                // }}
                onModalClose={() => {
                  setIsCalendlyPopupOpne(false);
                }}
                open={isCalendlyPopupOpne}
              ></PopupModal>
              <Button
                onClick={() => setIsCalendlyPopupOpne(!isCalendlyPopupOpne)}
                variant="contained"
                size="large"
              >
                Request a Demo
              </Button>
              <Button
                className="btn-free-trial"
                onClick={() => navigate('/users/login.html?tab=SIGN_UP')}
                variant="contained"
                size="large"
              >
                Create Your First Project
              </Button>
            </div>
          </div>
        </div>
      </div>
    </LandingPageLayout>
  );
}
