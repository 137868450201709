import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {AppDispatch, RootState} from '../../store/store';
import {logout} from '../../features/auth/authSlice';

export function AuthWatcher() {
  const dispatch: AppDispatch = useDispatch();
  const sessionExpirationTimeMs = useSelector(
    (state: RootState) => state.auth.sessionExpirationTimeMs
  );
  const sessionExpirationTimeEnabled = useSelector(
    (state: RootState) => state.auth.sessionExpirationTimeEnaibled
  );

  useEffect(() => {
    if (!sessionExpirationTimeEnabled) {
      console.log('Skip Session time expire validation');
      return;
    }

    if (sessionExpirationTimeMs == null) {
      dispatch(logout());
      return;
    }

    const nowMs = new Date().getTime();
    const timeoutMs = sessionExpirationTimeMs - nowMs;
    if (timeoutMs <= 0) {
      dispatch(logout());
      return;
    }

    const timeoutId = setTimeout(() => dispatch(logout()), timeoutMs);

    return () => clearTimeout(timeoutId);
  }, [sessionExpirationTimeMs]);

  return null;
}
