import {PropsWithChildren} from 'react';
import {useTheme} from '@mui/material/styles';
import {Footer} from '../../../LandingPage/PreviousComponents/Footer';
import {Header} from '../../../LandingPage/Header';

export function LandingPageLayout(props: PropsWithChildren) {
  const theme = useTheme();
  return (
    <div
      style={{
        background: theme.palette.background.default,
        color: theme?.palette?.text?.primary,
      }}
    >
      <Header></Header>
      {props.children}
      {/* <GetInTouch></GetInTouch> */}
      <Footer></Footer>
    </div>
  );
}
